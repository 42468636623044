import { Flex } from '@chakra-ui/react';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import { useEffect, useState } from 'react';
import AllAlert from '../components/message_alert/all_alert';
import Metadata from '../components/metadata';
import BlockContainer from '../components/modularBlocks/container';
import ResourceInteriorHeader from '../components/modularBlocks/resourceInteriorHeader';
import InteriorHeader from '../components/modularBlocks/section/interior_section_header';
import SectionBanner from '../components/modularBlocks/sectionBanner';
import LinkBanner from '../components/newsArticleComp/AllNewsLinkBanner';
import AdBanner from '../components/newsArticleComp/advertisements/adBanner';
import Search from '../components/searchComponents/overviewSearch';
import usePrefilters from '../hooks/usePrefilters';
import api from '../utils/api';
import { associateFilterIndices } from 'utils/associateFilterIndices';

export default function SearchPage({ ct: contentType, parent }) {
  const [data, setData] = useState();
  const path = window.location.pathname;

  const [prefilter, buildPrefilters] = usePrefilters();
  useEffect(() => {
    getData();
  }, [contentType]);

  const getData = async () => {
    // eslint-disable-next-line
    const info = contentType?.includes('overview')
      ? await api.getAllEntriesWithRef(
          contentType || 'news_overview',
          'leaderboard',
        )
      : await api.getArticle(contentType, path, 'en-us', 'leaderboard');

    if (parent === 'subjects') {
      buildPrefilters(contentType, 'subject', info[0]?.title);
    } else {
      buildPrefilters(contentType, parent, info[0]?.title);
    }
    setData(info[0]);
  };

  function toCaps(parent) {
    const final = [];
    const a = parent.split('-');
    a.map((word) => {
      final.push(word[0].toUpperCase() + word.slice(1));
    });
    return final.join(' ');
  }
  return (
    <main id="main">
      <SkipNavContent />
      {data && (
        <div>
          {data.seo && <Metadata data={data.seo} override={data} />}
          <AllAlert />
          {parent && (
            <LinkBanner
              data={{
                title: `All ${toCaps(parent)}`,
                href: `/resources/${parent}`,
              }}
            />
          )}
          {data.banner && <ResourceInteriorHeader data={data.banner} />}
          {data.section_hero_banner && (
            <SectionBanner data={data.section_hero_banner[0]} />
          )}
          {data.interior_section_header?.length > 0 && (
            <InteriorHeader data={data.interior_section_header[0]} />
          )}

          {data.page_content && <BlockContainer data={data.page_content} />}
          {data.leaderboard?.length > 0 && (
            <Flex
              m={'23px auto 0'}
              px={{ base: '16px', md: '32px', lg: '60px' }}
              flex={1}
            >
              <AdBanner data={data.leaderboard} />
            </Flex>
          )}
          <Search
            cta={data.call_to_action}
            index={contentType}
            prefilter={prefilter}
            filters={data.search_filters}
            ct={contentType}
            sortfilters={associateFilterIndices(data)}
          />
        </div>
      )}
    </main>
  );
}
