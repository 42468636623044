import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useParams } from 'react-router-dom';
import {
  Container,
  Stack,
  Flex,
  Text,
  Box,
  CloseButton,
  Link,
  useDisclosure,
} from '@chakra-ui/react';

export default function MessageAlert(props) {
  const { l } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getAllEntries('message_alert', l);
    setData(info[0]);
  }
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box>
      {data ? (
        isVisible ? (
          <Container
            as={Stack}
            color="#FFFFFF"
            backgroundColor="DarkBlue"
            minW="100%"
            direction={{ base: 'column', md: 'row' }}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
          >
            <Container
              minW="100%"
              as={Flex}
              flexDirection="row"
              justifyContent="space-around"
            >
              <Stack
                flexGrow={3}
                minWidth="100%"
                direction={{ base: 'column', md: 'row' }}
                justifyContent="center"
                align="center"
                spacing={{ base: '0.5', md: '1.5' }}
              >
                <Text
                  textAlign="center"
                  justifySelf="end"
                  width={{ base: '100%', md: 'auto' }}
                  paddingLeft="32px"
                  paddingRight="32px"
                  paddingTop="8px"
                  paddingBottom="8px"
                  maxWidth="1200px"
                >
                  {data.message}{' '}
                  {data.url_path?.external ? (
                    <Link
                      as="u"
                      fontWeight="medium"
                      href={data.url_path?.link?.href}
                      target={data.url_path?.new_window ? '_blank' : '_self'}
                    >
                      {data.url_path?.link?.title}
                    </Link>
                  ) : (
                    <Link
                      fontWeight="medium"
                      href={data.url_path?.link?.href}
                      target={data.url_path?.new_window ? '_blank' : ''}
                    >
                      {data.url_path?.link?.title}
                    </Link>
                  )}
                </Text>
                {/* {data.url_path?.external ? (
                  <Link
                    href={data.url_path?.link?.href}
                    target={data.url_path?.new_window ? '_blank' : '_self'}
                  >
                    {data.url_path?.link?.title}
                  </Link>
                ) : (
                  <Link
                    as={NavLink}
                    to={data.url_path?.link?.href}
                    target={data.url_path?.new_window ? '_blank' : '_self'}
                  >
                    {data.url_path?.link?.title}
                  </Link>
                )} */}
              </Stack>
              <Box flexGrow={0.03}>
                <CloseButton flexDirection="end" onClick={onClose} />
              </Box>
            </Container>
          </Container>
        ) : null
      ) : (
        ''
      )}
    </Box>
  );
}
