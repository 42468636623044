import {
  Flex,
  Heading,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import RichTextEditorMB from '../rte';
import RightArrow from '../../icons/rightArrow';
// import ChevronRightIcon from '../../icons/chevronRight';
// import ChevronDownIcon from '../../icons/chevronDown';
import InnerAccordion from './innerAccordion';

export default function AccordionMB(props) {
  const { refData } = props;
  const { l } = useParams();
  const [data, setData] = useState();
  const [cols, setCols] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      refData?._content_type_uid,
      refData?.uid,
      l,
      'entry.inner_accordion',
    );
    setData(info.entry);
    divideCols(info.entry);
  }

  function divideCols(data) {
    const one = data.entry?.slice(
      0,
      data.entry.length % 2 === 0
        ? Math.floor(data.entry.length / 2)
        : Math.floor(data.entry.length / 2) + 1,
    );
    const two = data.entry?.slice(
      data.entry.length % 2 === 0
        ? Math.floor(data.entry.length / 2)
        : Math.floor(data.entry.length / 2) + 1,
    );
    setCols(() => [one, two]);
  }
  return (
    <Box
      id={data?.title?.toLowerCase().replaceAll(' ', '-')}
      bgColor={'#EBEBEB'}
      py={{ base: '24px', md: '32px' }}
    >
      <Box
        maxW={{ base: '84%', md: '781px', lg: '1145px' }}
        w={{ md: '86%', lg: '80%' }}
        mx={'auto'}
      >
        <Flex
          direction={'column'}
          gap={'21px'}
          mb={{
            base: '20px',
            // md: '45px',
            // lg: '60px',
          }}
        >
          {data?.heading && (
            <Heading
              fontSize={{ base: '2.188rem', md: '2.25rem' }}
              fontWeight={'700'}
              lineHeight={'2.5rem'}
            >
              {data?.heading}
            </Heading>
          )}
          {data?.heading_text && (
            <Box
              fontSize={{ base: '1.125rem', md: '1.25rem' }}
              lineHeight={'2.125rem'}
              letterSpacing={'-.02em'}
            >
              <RichTextEditorMB data={data?.heading_text} px="initial" />
            </Box>
          )}
        </Flex>
        <Flex>
          <Accordion allowToggle w={'100%'}>
            <Flex
              direction={
                data?.two_column ? { base: 'column', md: 'row' } : 'column'
              }
              gap={
                data?.two_column
                  ? { base: '0', md: '2rem', lg: '3.188rem' }
                  : '-9.5px'
              }
              w={'100%'}
              // border={'solid red 2px'}
            >
              {cols?.map((col, i) => {
                return (
                  <Box
                    // border={'yellow solid 2px'}
                    key={i}
                    w={data?.two_column ? { base: '100%', md: '50%' } : '100%'}
                    mt={
                      i === 1 && data?.two_column
                        ? { base: '-9.5px', md: 'initial' }
                        : i === 1
                        ? '-9.5px'
                        : 'initial'
                    }
                  >
                    {col.map((entry, i) => {
                      return (
                        <AccordionItem
                          key={i}
                          // w={'80%'}
                          bgColor="white"
                          my={
                            data.two_column
                              ? { base: '9.5px', md: '.969rem', lg: '1.625rem' }
                              : '9.5px'
                          }
                          mx={'auto'}
                          borderRadius={'20px'}
                        >
                          {({ isExpanded }) => {
                            return (
                              <>
                                <AccordionButton
                                  _expanded={{ color: 'DarkGreen' }}
                                  gap={'2rem'}
                                  minH={'5.125rem'}
                                  fontWeight={'600'}
                                  lineHeight={'1.875rem'}
                                  fontSize={'1.25rem'}
                                  borderRadius={'20px'}
                                >
                                  <AccordionIcon />
                                  {/* <ChevronDownIcon color={'DarkGreen'} /> */}
                                  {/* <AccordionIcon /> */}
                                  {/* {isExpanded ? (
                                    // <Icon as={<ChevronDownIcon />} />
                                    <ChevronDownIcon color={'DarkGreen'} />
                                  ) : (
                                    <ChevronRightIcon />
                                  )} */}
                                  <Box flex="1" textAlign="left">
                                    {entry.title}
                                  </Box>
                                </AccordionButton>

                                <AccordionPanel pl={'4.25rem'}>
                                  <Box
                                    key={i}
                                    //   overflow={'hidden'}
                                    fontWeight={'400'}
                                    lineHeight={'2.125rem'}
                                    fontSize={'1.25rem'}
                                    mb={'1rem'}
                                  >
                                    <RichTextEditorMB
                                      data={entry.text}
                                      px="initial"
                                      // style={['card']}
                                    />
                                  </Box>

                                  {entry.inner_accordion?.length > 0 && (
                                    <InnerAccordion
                                      data={entry.inner_accordion[0]}
                                    />
                                  )}
                                  {entry.cta_link?.title && (
                                    <Flex gap={'1rem'} align="center">
                                      <Link
                                        href={entry.cta_link?.href}
                                        fontSize={{ base: '1rem' }}
                                        fontWeight={{ base: '600' }}
                                        lineHeight={'1.25rem'}
                                        color={'DarkGreen'}
                                      >
                                        {entry.cta_link?.title}
                                      </Link>
                                      <RightArrow
                                        style={{ color: 'DarkGreen' }}
                                      />
                                    </Flex>
                                  )}
                                </AccordionPanel>
                              </>
                            );
                          }}
                        </AccordionItem>
                      );
                    })}
                  </Box>
                );
              })}
            </Flex>
          </Accordion>
        </Flex>
      </Box>
    </Box>
  );
}

// Old duplicate code:
{
  /* <Box
// border={'yellow solid 2px'}
w={data?.two_column ? { base: '100%', md: '50%' } : '100%'}
>
{data?.entry
  ?.slice(
    0,
    data.entry.length % 2 === 0
      ? Math.floor(data.entry.length / 2)
      : Math.floor(data.entry.length / 2) + 1,
  )
  .map((entry, i) => {
    return (
      <AccordionItem
        key={i}
        // w={'80%'}
        bgColor="white"
        my={
          data.two_column
            ? { base: '9.5px', md: '.969rem', lg: '1.625rem' }
            : '9.5px'
        }
        mx={'auto'}
        borderRadius={'20px'}
      >
        {({ isExpanded }) => {
          return (
            <>
              <AccordionButton
                _expanded={{ color: 'DarkGreen' }}
                gap={'2rem'}
                minH={'5.125rem'}
                fontWeight={'600'}
                lineHeight={'1.25rem'}
                fontSize={'1.25rem'}
                borderRadius={'20px'}
              >
                
                {isExpanded ? (
                  <FontAwesomeIcon
                    icon={solid('chevron-down')}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={solid('chevron-right')}
                  />
                )}
                <Box flex="1" textAlign="left">
                  {entry.title}
                </Box>
              </AccordionButton>

              <AccordionPanel pl={'4.25rem'}>
                <Box
                  key={i}
                  //   overflow={'hidden'}
                  fontWeight={'400'}
                  lineHeight={'2.125rem'}
                  fontSize={'1.25rem'}
                  mb={'1rem'}
                >
                  <RichTextEditorMB
                    data={entry.text}
                    px="initial"
                    // style={['card']}
                  />
                </Box>

                {entry.inner_accordion?.length > 0 && (
                  <InnerAccordion
                    data={entry.inner_accordion[0]}
                  />
                )}
                {entry.cta_link?.title && (
                  <Flex gap={'1rem'} align="center">
                    <Link
                      as={RouterLink}
                      to={entry.cta_link?.href}
                      fontSize={{ base: '1rem' }}
                      fontWeight={{ base: '600' }}
                      lineHeight={'1.25rem'}
                      color={'DarkGreen'}
                    >
                      {entry.cta_link?.title}
                    </Link>
                    <FontAwesomeIcon
                      icon={solid('arrow-right-long')}
                      color={'DarkGreen'}
                    />
                  </Flex>
                )}
              </AccordionPanel>
            </>
          );
        }}
      </AccordionItem>
    );
  })}
</Box>
<Box
// border={'green solid 2px'}
mt={
  data?.two_column
    ? { base: '-9.5px', md: 'initial' }
    : '-9.5px'
}
w={data?.two_column ? { base: '100%', md: '50%' } : '100%'}
>
{data?.entry
  ?.slice(
    data.entry.length % 2 === 0
      ? Math.floor(data.entry.length / 2)
      : Math.floor(data.entry.length / 2) + 1,
  )
  .map((entry, i) => {
    return (
      <AccordionItem
        key={i}
        // w={'80%'}
        bgColor="white"
        my={
          data.two_column
            ? { base: '9.5px', md: '.969rem', lg: '1.625rem' }
            : '9.5px'
        }
        mx={'auto'}
        borderRadius={'20px'}
      >
        {({ isExpanded }) => {
          return (
            <>
              <AccordionButton
                // border={'pink 3px dashed'}
                _expanded={{ color: 'DarkGreen' }}
                gap={'2rem'}
                minH={'5.125rem'}
                fontWeight={'600'}
                lineHeight={'1.25rem'}
                fontSize={'1.25rem'}
                borderRadius={'20px'}
              >
                
                {isExpanded ? (
                  <FontAwesomeIcon
                    icon={solid('chevron-down')}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={solid('chevron-right')}
                  />
                )}
                <Box flex="1" textAlign="left">
                  {entry.title}
                </Box>
              </AccordionButton>

              <AccordionPanel pl={'4.25rem'}>
                <Box
                  key={i}
                  //   overflow={'hidden'}
                  fontWeight={'400'}
                  lineHeight={'2.125rem'}
                  fontSize={'1.25rem'}
                  mb={'1rem'}
                >
                  <RichTextEditorMB
                    data={entry.text}
                    px="initial"
                  />
                </Box>
                {entry.inner_accordion?.length > 0 && (
                  <InnerAccordion
                    data={entry.inner_accordion[0]}
                  />
                )}
                {entry.cta_link?.title && (
                  <Flex gap={'1rem'} align="center">
                    <Link
                      as={RouterLink}
                      to={entry.cta_link?.href}
                      fontSize={{ base: '1rem' }}
                      fontWeight={{ base: '600' }}
                      lineHeight={'1.25rem'}
                      //   color={'DarkGreen'}
                    >
                      {entry.cta_link?.title}
                    </Link>
                    <FontAwesomeIcon
                      icon={solid('arrow-right-long')}
                      color={'DarkGreen'}
                    />
                  </Flex>
                )}
              </AccordionPanel>
            </>
          );
        }}
      </AccordionItem>
    );
  })}
</Box> */
}
