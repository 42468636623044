import { Box, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import MainCard from './mainCard';
import PopOut from './popOut';

export default function MeetTheStaff(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    getData(l);
  }, [l]);

  useEffect(() => {
    if (newData) {
      setExpanded(newData.card_group.map(() => false));
    }
  }, [newData]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data?._content_type_uid,
      data?.uid,
      l,
      'card_group.headshot.headshot',
    );

    setNewData(info.entry);
  }

  return (
    <Box px={'2%'} my={10}>
      {newData && (
        <Box
          maxW={'1240px'}
          mx={{ base: '1rem', md: 'auto' }}
          display="flex"
          flexDirection="column"
          justifyContent={{ base: 'center', md: 'start' }}
          alignContent={{ base: 'center', md: 'start' }}
        >
          <Box textAlign={{ base: 'center', md: 'start' }}>
            <Heading fontSize="40px">{newData.headline}</Heading>
          </Box>
          <Box
            alignSelf={{ base: 'center', md: 'start' }}
            marginTop="40px"
            marginBottom="36px"
            width={{ md: '310px', base: '245px' }}
            borderBottom="2px #C5C1C1 solid"
          ></Box>
          <Box>
            {newData.card_group?.map((group, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent={{ base: 'center', md: 'start' }}
                  alignContent={{ base: 'center', md: 'start' }}
                  flexWrap="wrap"
                  gap="40px"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={{ base: 'center', md: 'start' }}
                    alignItems={{ base: 'center', md: 'start' }}
                    textAlign={{ base: 'center', md: 'start' }}
                  >
                    <Text marginBottom="35px" fontWeight="700" fontSize="30px">
                      {group.group_title}
                    </Text>
                    <Box
                      display="grid"
                      // gridTemplateColumns={`repeat(auto-fit, minmax(320px, 1fr))`}
                      gridTemplateColumns={{
                        lg: 'repeat(5, 1fr)',
                        md: 'repeat(3,1fr)',
                        base: 'repeat(1, 1fr)',
                      }}
                      gridGap={{ lg: '30px', md: '24px', base: '20px' }}
                      marginY="30px"
                      gridAutoFlow={'dense'}
                    >
                      {group.headshot?.map((member, j) => {
                        if (member?.headshot[0]?.staff_bio) {
                          return [
                            <MainCard
                              key={i}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              group={group}
                            />,
                            <PopOut
                              key={i + 'pop'}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              mt={'-30px'}
                            />,
                          ];
                        }
                        return (
                          <Box key={j} w={'215px'}>
                            <MainCard
                              key={i}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              group={group}
                            />
                            <PopOut
                              key={i + 'pop'}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}
