import {
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Bronze,
  Diamond,
  Gold,
  Group,
  Platinum,
  Silver,
} from 'assets/membershiptBadges';
import RightArrow from 'components/icons/rightArrow';
import RichTextEditorMB from 'components/modularBlocks/rte';
import useDateFormat from 'hooks/useDateFormat';
import { useEffect, useState } from 'react';
import logger from 'utils/logger';

const EntityResultView = ({ result }) => {
  const [date, setDate] = useState();
  const [location, setLocation] = useState();
  const [link, setLink] = useState();
  const [title, setTitle] = useState();
  const [memberTier, setTier] = useState();
  const [privacyVendor, setPrivacyVendor] = useState();
  const [seeMore, setSeeMore] = useState(true);
  const [services, setServices] = useState();
  const [dateBuilder] = useDateFormat();
  const theme = useTheme();

  useEffect(() => {
    formatTagInfo();
    if (result.published_dtm) {
      setDate(() => dateBuilder(new Date(result.published_dtm), 'ap')); // this builds the date based on the hook
    }
  }, []);

  const formatTagInfo = () => {
    let dateArray = [];
    const services = [];
    result.tags?.map((tag) => {
      // This will be used if there is a date tag
      if (tag.includes('publish_date')) {
        const x = tag.split('=');
        if (x[1]) {
          return (dateArray = new Date(x[1]).toLocaleDateString().split('/'));
        }
      }
      if (tag.includes('location')) {
        const x = tag.split('=');
        return setLocation(x[1]);
      }
      if (tag.includes('cta_link')) {
        const x = tag.split('=');
        return setLink(() => x[1]);
      }
      if (tag.includes('cta_title')) {
        const x = tag.split('=');
        return setTitle(() => x[1]);
      }
      if (tag.includes('Status')) {
        const x = tag.split('=');
        return setTier(x[1]);
      }
      if (tag.includes('Privacy-Vendor')) {
        const x = tag.split('=');
        return setPrivacyVendor(x[1]);
      }
      if (tag.includes('services')) {
        const x = tag.split('=');
        services.push(x[1]);
        return setServices(services);
      }
      return;
    });

    const months = [
      'Jan.',
      'Feb.',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug.',
      'Sept.',
      'Oct.',
      'Nov.',
      'Dec.',
    ];
    dateArray[0] = months[Number(dateArray[0]) - 1];

    if (dateArray.length === 3) {
      return setDate(`${dateArray[1]} ${dateArray[0]} ${dateArray[2]}`);
    }
  };

  // the following logic will be used whe privacy vendor data is getting pulled through
  // tags section. When uncommenting make sure to uncomment closing "}" around line 189
  // to prevent errors. Consoling privacy vendor to prevent error; can delete when uncommenting
  logger.log('privacyVendor', privacyVendor);
  // if (result.category === 'organization_list' && privacyVendor !== 'true') {
  //   return;
  // } else {
  return (
    <Flex
      border={`1px solid ${theme.colors.Grey3}`}
      // w={'75%'}
      direction={'column'}
      p={'16px 48px'}
      gap={'10px'}
      className={'Hoverable'}
      _hover={{ bg: theme.colors.Grey4 }}
      w={{ base: '100%', md: '100%', lg: '100%' }}
    >
      <Flex opacity={'75%'} fontSize={'1.125rem'} lineHeight={'1.5rem'}>
        {result.category === 'organization_list' &&
          (location ? <Text>{location}</Text> : '')}
        {result.category === 'otp_listing_page' &&
          (location ? <Text>{location}</Text> : '')}
        {result.category !== 'find_your_chapter' &&
          (date && date !== '20 Jan. 1970' ? (
            location ? (
              <Text>{`${date} / ${location}`}</Text>
            ) : (
              <Text>{date}</Text>
            )
          ) : location ? (
            <Text>{location}</Text>
          ) : (
            ''
          ))}
        {/* {result.category === 'organization_list' &&
          (location ? <Text>{location}</Text> : '')} */}
      </Flex>
      <Flex direction={'column'}>
        <Heading fontWeight={'700'} fontSize={'1.25rem'} lineHeight={'1.97rem'}>
          {result.name}
        </Heading>
        {services && (
          <Text fontWeight={600} py={'10px'}>
            Services: {services.join('| ')}
          </Text>
        )}
        {result.subcategory?.replaceAll(' ', '_').toLowerCase() !==
          result.category &&
          result.subcategory !== 'subCategory' &&
          result.subcategory !== 'Network Activites' && (
            <Text
              fontFamily={'Lato'}
              fontSize={'1rem'}
              lineHeight={'1.97rem'}
              fontWeight={'600'}
            >
              {result.subcategory}
            </Text>
          )}
      </Flex>
      {result.description &&
        result.description !== 'description' &&
        result.description !== ' ' && (
          <Flex direction={'column'} w={'fit-content'}>
            <Button
              onClick={() => setSeeMore(!seeMore)}
              w={'fit-content'}
              leftIcon={
                seeMore ? (
                  <FontAwesomeIcon icon={solid('circle-plus')} />
                ) : (
                  <FontAwesomeIcon icon={solid('circle-minus')} />
                )
              }
              color={'DarkGreen'}
              background={'inherit'}
              pl={0}
              justifyContent={'start'}
            >
              {seeMore ? 'See More' : 'See Less'}
            </Button>
            <Flex
              display={seeMore ? 'none' : 'flex'}
              gap={'30px'}
              my={'20px'}
              w={'auto'}
              // border={'solid teal 2px'}
            >
              {(result.imageUri !== '' || memberTier) && (
                <Image
                  alt="fill"
                  src={
                    result.category === 'organization_list'
                      ? assignBadge(memberTier)
                      : result.imageUri
                  }
                  boxSize={'96px'}
                  fit={'contain'}
                />
              )}
              {/* {(result.image !== '' ||
                result.imageUri !== '' ||
                memberTier) && (
                <Image
                  src={
                    result.category === 'organization_list'
                      ? assignBadge(memberTier)
                      : result.imageUri
                  }
                  boxSize={'96px'}
                  fit={'contain'}
                />
              )} */}
              {/* <Image src={Gold} boxSize={'96px'} /> */}
              <RichTextEditorMB
                data={result.description}
                style={['card']}
                px={'initial'}
                pr={'60px'}
                w={'auto%'}
                margin={'0, 0, 0, 0'}
                padding={'0, 0, 20, 0'}
              />
            </Flex>
          </Flex>
        )}
      {link && (
        <Flex
          gap="16px"
          align={'center'}
          fontWeight={'600'}
          fontSize={'1rem'}
          lineHeight={'1.25rem'}
        >
          <Link href={link} target={'_blank'}>
            {title ? title : 'View'}
          </Link>
          <RightArrow />
        </Flex>
      )}
    </Flex>
  );
  // }
};

const assignBadge = (badgeName) => {
  switch (badgeName) {
    case 'Diamond':
      return Diamond;
    case 'Bronze':
      return Bronze;

    case 'Gold':
      return Gold;

    case 'Platinum':
      return Platinum;

    case 'Silver':
      return Silver;

    default:
      return Group;
  }
};

export default EntityResultView;
