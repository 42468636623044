import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Image, Link, useTheme } from '@chakra-ui/react';
import Fallback from 'assets/vectors/fallbackGrey.svg';
import { formatDate } from './formatDate';
import logger from 'utils/logger';

const CustomResultView = ({ result, onClickLink }) => {
  const [date, setDate] = useState();
  const theme = useTheme();
  if (!result) {
    return null;
  }
  logger.log('result in customresult view', result);
  useEffect(() => {
    setDate(formatDate(result.published_dtm));
  }, []);
  return (
    <Flex
      key={result.objectID}
      as={Link}
      href={result.uri}
      border={`solid 1px ${theme.colors.Grey3}`}
      p="8px"
      justify={'space-between'}
      borderRadius={'24px'}
      w={{ base: '100%', lg: '48.5%' }}
      _hover={{
        textDecor: 'none',
        boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.07)',
      }}
      role="group"
      mb="16px"
    >
      <Flex
        direction={'column'}
        gap={'8px'}
        flex={1}
        // border={'solid hotpink 1px'}
        m={'8px'}
        justify={'center'}
        textOverflow={'ellipsis'}
      >
        <Flex direction={'row'} gap={'8px'} flexWrap={'wrap'}>
          {result.category === 'news_article' && (
            <Box
              color={'DarkGreen'}
              borderColor={'DarkGreen'}
              border={'solid 1px'}
              w={'max-content'}
              borderRadius={'20px'}
              textAlign={'center'}
              px={'16px'}
            >
              <Text
                letterSpacing={'.035em'}
                fontWeight={'600'}
                fontSize={'.875rem'}
                lineHeight={'1.188rem'}
              >
                IAPP ORIGINAL
              </Text>
            </Box>
          )}
          {result.tags?.includes('internal_class=analysis') && (
            <Box
              color={`${theme.colors.DarkBlue}`}
              borderColor={`${theme.colors.DarkBlue}`}
              border={'solid 1px'}
              w={'max-content'}
              borderRadius={'20px'}
              textAlign={'center'}
              px={'12px'}
            >
              <Text
                letterSpacing={'3.5%'}
                fontWeight={'600'}
                fontSize={'14px'}
                lineHeight={'1.188rem'}
                fontFamily={'Open Sans, semi-bold'}
              >
                ANALYSIS
              </Text>
            </Box>
          )}
        </Flex>

        <Text
          color={'#4D4D4D'}
          fontWeight={'600'}
          fontSize={'.875rem'}
          lineHeight={'1.188rem'}
          letterSpacing={'.08em'}
        >
          {date}
        </Text>
        <Flex textOverflow={'ellipsis'}>
          <Text
            fontWeight={'600'}
            fontSize={'1rem'}
            lineHeight={'1.688rem'}
            _groupHover={{ textDecor: 'underline' }}
            noOfLines={3}
          >
            {result.name}
          </Text>
        </Flex>
      </Flex>
      <Flex
        // border={'red solid 2px'}
        h={'auto'}
        flex={1}
        maxWidth={{
          lg: '160px',
          md: '160px',
          sm: '130px',
          base: '160px',
        }}
      >
        {result.category === 'news_article' && (
          <Image
            alt={result.name}
            src={result.imageUri}
            fallbackSrc={Fallback}
            fit={'cover'}
            borderRadius={'0 16px 16px 0'}
            //    loading={'lazy'}
          />
        )}
        {result.category === 'news_blurb' && (
          <Image
            alt={result.name}
            src={result.imageUri}
            fallbackSrc={Fallback}
            fit={'cover'}
            borderRadius={'0 15px 15px 0'}
            //    loading={'lazy'}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CustomResultView;
