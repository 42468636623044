import { useEffect } from 'react';

const OneTrustScripts = () => {
  useEffect(() => {
    const oneTrustId = process.env.REACT_APP_ONETRUST_ID; // Accessing environment variable
    if (!oneTrustId) {
      console.error('OneTrust ID not found! ');
      return;
    }

    const id =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? oneTrustId
        : oneTrustId + '-test';
    // Dynamically create the script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
    script.setAttribute('data-domain-script', id);
    const head = document.head;
    head.children.length > 0
      ? head.insertBefore(script, head.children[1] || null)
      : head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.textContent = `function OptanonWrapper() { window.dataLayer.push({ event: 'OneTrustGroupsUpdated' }); }`;
    // Insert the inline script right after the OneTrust script
    script.parentNode.insertBefore(inlineScript, script.nextSibling);
  }, []);

  return null; // This component does not render anything
};

export default OneTrustScripts;
