import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import ImageMB from '../image';
import RichTextEditorMB from '../rte';
import Banner from '../banner';
import ButtonMB from '../button';
import Carousel from '../carousels/carousel';
// import ReferenceCarousel from '../carousels/refCarousel';
import SectionHeader from '../section/section_header';
import MenuMB from '../drop_menu';
import Columns from '../columns';
import Video from '../videoPlayer';
import Section from '../section';
import PromoCards from '../promo_cards';
import Stack from '../stack';
import NewsBanner from '../../newsBanner';
import TabCarousel from '../carousels/tabCarousel';
import TrainingHeader from '../section/training_header';
import TrainingStacks from '../trainingStacks';
import AdBlock from '../adBlock';
import CardLayout from '../cardsLayout';
import FeatureCard from '../featureCard';
import CTACallout from '../cta_callout';
import ContentCallOut from '../contentCallOut';
import InteriorHeader from '../section/interior_section_header';
import PromoBanner from '../promoBanner';
import AccordionMB from '../accordion';
import SectionBanner from '../sectionBanner';
import ResourcesHero from '../resourcesHero';
import TableOfContents from '../tableOfContents';
import MeetTheStaff from '../meetTheStaff';
import ImageSlider from '../imageSlider';
import ImageRTESlider from '../imageRTESlider';
import InformationCards from '../informationCards';
import ModalMB from '../modal';
import CtaButton from '../button/ctaButton';
import Meetings from '../meetings';
import TableMB from '../table';
import Sponsors from '../sponsors';
import LegendModal from '../modal/legendModal';
import EntityListing from '../../entityListing';
import logger from 'utils/logger';

export default function BlockContainer(props) {
  const { data, px, fit, pos, rtecontainerpx, ...rest } = props;
  return (
    <Box w={'100%'}>
      {data ? (
        <Flex direction={'column'}>
          {data.map((block, i) => {
            if (block.image) {
              return (
                <Box my="2" key={i} w={'100%'} px={'2%'}>
                  {
                    // Array.isArray(block.image.image) ? (
                    //   <ImageMB
                    //     refData={block.image.image[0]}
                    //     fit={fit}
                    //     pos={pos}
                    //   />
                    // )
                    block.image.image_block?.length > 0 ? (
                      <ImageMB
                        refData={block.image.image_block[0]}
                        fit={fit}
                        pos={pos}
                        {...rest}
                      />
                    ) : (
                      <ImageMB imageObj={block.image} fit={fit} pos={pos} />
                    )
                  }
                </Box>
              );
            } else if (block.rte) {
              return (
                <Box
                  my="2"
                  key={i}
                  //   border={'dashed green 2px'}
                  w={'100%'}
                  px={'2%'}
                >
                  <RichTextEditorMB
                    key={i}
                    data={block.rte.rich_text_editor}
                    style={block.rte.custom_styles}
                    px={px}
                    rtecontainerpx={rtecontainerpx}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.banner) {
              return (
                <Box w={'100%'} key={i}>
                  <Banner data={block.banner} {...rest} />
                </Box>
              );
            } else if (block.button) {
              return (
                <Box w={'100%'} key={i}>
                  {Array.isArray(block.button.button) ? (
                    <ButtonMB refData={block.button.button[0]} {...rest} />
                  ) : (
                    <ButtonMB buttonObj={block.button} {...rest} />
                  )}
                </Box>
              );
            } else if (block.cta_button_block) {
              return (
                <Box w={'100%'} key={i}>
                  <CtaButton
                    data={block.cta_button_block.cta_button_block[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.carousel || block.reference_carousel) {
              return (
                <Box w={'100%'} key={i}>
                  <Carousel
                    data={block.carousel}
                    refData={block.reference_carousel}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.section?.section) {
              return (
                <Box w={'100%'} key={i}>
                  <Section refData={block.section.section[0]} {...rest} />
                </Box>
              );
            } else if (block.section) {
              return (
                <Box w={'100%'} key={i}>
                  <Section data={block.section} {...rest} />
                </Box>
              );
            } else if (block.help_drop_menu) {
              return (
                <Box w={'100%'} key={i}>
                  <MenuMB data={block.help_drop_menu} {...rest} />
                </Box>
              );
            } else if (block.drop_menu) {
              return (
                <Box w={'100%'} key={i}>
                  <MenuMB refData={block.drop_menu.drop_menu[0]} {...rest} />
                </Box>
              );
            } else if (block.section_header) {
              return (
                <Box w={'100%'} key={i}>
                  <SectionHeader data={block.section_header} {...rest} />
                </Box>
              );
            } else if (block.column_section) {
              return (
                <Box w={'100%'} key={i}>
                  <Columns data={block.column_section} {...rest} />
                </Box>
              );
            } else if (block.columns) {
              return (
                <Box w={'100%'} key={i}>
                  <Columns refData={block.columns.columns[0]} {...rest} />
                </Box>
              );
            } else if (block.video_player || block.video) {
              return (
                <Box w={'100%'} key={i}>
                  <Video data={block.video_player || block.video} {...rest} />
                </Box>
              );
            } else if (block.three_card_promo) {
              return (
                <Box w={'100%'} key={i}>
                  <Stack data={block.three_card_promo} {...rest} />
                </Box>
              );
            } else if (block.promo_stack_three) {
              return (
                <Box w={'100%'} key={i}>
                  <Stack
                    refData={block.promo_stack_three.promo_stack_x3[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.promo_cards) {
              return (
                <Box w={'100%'} key={i}>
                  <PromoCards
                    data={block.promo_cards.promo_cards[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.headlines) {
              return (
                <Box w={'100%'} key={i}>
                  <NewsBanner data={block.headlines} {...rest} />
                </Box>
              );
            } else if (block.tab_carousel) {
              return (
                <Box w={'100%'} key={i}>
                  <TabCarousel data={block.tab_carousel} {...rest} />
                </Box>
              );
            } else if (block.training_section_header) {
              return (
                <Box w={'100%'} key={i}>
                  <TrainingHeader
                    data={block.training_section_header.section_header[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.interior_section_header) {
              return (
                <Box w={'100%'} key={i}>
                  <InteriorHeader
                    data={
                      block.interior_section_header.interior_section_header[0]
                    }
                    {...rest}
                  />
                </Box>
              );
            } else if (block.promo_banner_with_icon) {
              return (
                <Box w={'100%'} key={i}>
                  <PromoBanner
                    data={
                      block.promo_banner_with_icon.promo_banner_with_icon[0]
                    }
                    {...rest}
                  />
                </Box>
              );
            } else if (block.section_hero_banner) {
              return (
                <Box w={'100%'} key={i}>
                  <SectionBanner
                    data={block.section_hero_banner.section_hero_banner[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.training_cards) {
              return (
                <Box w={'100%'} key={i}>
                  <TrainingStacks
                    data={block.training_cards.training_cards_section[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.ad_block) {
              return (
                <Box w={'100%'} key={i}>
                  <AdBlock data={block.ad_block.ad_block[0]} {...rest} />
                </Box>
              );
            } else if (block.cards_layout) {
              return (
                <Box w={'100%'} key={i}>
                  <CardLayout
                    data={block.cards_layout.cards_layout[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.feature_card) {
              return (
                <Box w={'100%'} key={i}>
                  <FeatureCard
                    data={block.feature_card.feature_card[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.cta_callout_x3) {
              return (
                <Box w={'100%'} key={i}>
                  <CTACallout
                    data={block.cta_callout_x3.cta_callout_x3[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.content_call_out) {
              return (
                <Box w={'100%'} key={i}>
                  <ContentCallOut
                    refData={block.content_call_out.content_call_out[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.accordion) {
              return (
                <Box w={'100%'} key={i}>
                  <AccordionMB
                    refData={block.accordion.accordion[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.resources_hero) {
              return (
                <Box w={'100%'} key={i}>
                  <ResourcesHero
                    refData={block.resources_hero.resources_hero[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.table_of_contents) {
              return (
                <Box w={'100%'} key={i}>
                  <TableOfContents
                    data={block.table_of_contents.table_of_contents[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.meet_the_staff) {
              return (
                <Box w={'100%'} key={i}>
                  <MeetTheStaff
                    data={block.meet_the_staff.meet_the_staff[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.image_slider) {
              return (
                <Box w={'100%'} key={i}>
                  <ImageSlider
                    data={block.image_slider.image_slider[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.keynote_speaker_slider) {
              return (
                <Box w={'100%'} key={i}>
                  <ImageRTESlider
                    data={
                      block.keynote_speaker_slider.keynote_speaker_slider[0]
                    }
                    {...rest}
                  />
                </Box>
              );
            } else if (block.information_cards) {
              return (
                <Box w={'100%'} key={i}>
                  <InformationCards
                    data={block.information_cards.information_cards[0]}
                    isComplex={false}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.card_complex_info) {
              return (
                <Box w={'100%'} key={i}>
                  <InformationCards
                    data={block.card_complex_info.card_complex_info[0]}
                    isComplex={true}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.meetings) {
              return (
                <Box w={'100%'} key={i}>
                  <Meetings data={block.meetings.meetings[0]} {...rest} />
                </Box>
              );
            } else if (block.modal_block) {
              return (
                <Box w={'100%'} key={i}>
                  <ModalMB data={block.modal_block.modal_block[0]} {...rest} />
                </Box>
              );
            } else if (block.legend_modal) {
              return (
                <Box w={'100%'} key={i}>
                  <LegendModal
                    data={block.legend_modal.legend_modal[0]}
                    {...rest}
                  />
                </Box>
              );
            } else if (block.table) {
              return (
                <Box w={'100%'} key={i}>
                  <TableMB data={block.table.table[0]} {...rest} />
                </Box>
              );
            } else if (block.sponsors) {
              return (
                <Box w={'100%'} key={i}>
                  <Sponsors data={block.sponsors.sponsors[0]} {...rest} />
                </Box>
              );
            } else if (block.entry_listing) {
              return (
                <Box w={'100%'} key={i}>
                  <EntityListing
                    refData={block.entry_listing.entry_listing[0]}
                    {...rest}
                  />
                </Box>
              );
            } else {
              logger.warn('Error: unsupported entry in <BlockContainer>');
            }
          })}
        </Flex>
      ) : (
        logger.warn('NO DATA in <BlockContainer/>')
      )}
    </Box>
  );
}
