import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Languages } from '../../config';
import { useParams } from 'react-router-dom';
import striptags from 'striptags';

export default function Metadata(props) {
  const { data, override } = props;
  const { l } = useParams();

  const reorderHeadElements = () => {
    const head = document.head;
    const childNodes = Array.from(head.childNodes);

    const compare = (a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      if (nameA === 'description' && nameB !== 'description') {
        return -1;
      } else if (nameB === 'description' && nameA !== 'description') {
        return 1;
      } else {
        return 0;
      }
    };

    const metaTags = childNodes
      .filter((node) => node.nodeName === 'META')
      .sort(compare);

    metaTags.sort(compare);

    const titleTags = childNodes.filter((node) => node.nodeName === 'TITLE');

    // Clear existing tags
    metaTags.forEach((meta) => head.removeChild(meta));
    titleTags.forEach((title) => head.removeChild(title));

    // Append sorted tags
    metaTags.forEach((tag) => head.insertBefore(tag, head.firstChild));
    titleTags.forEach((tag) => head.insertBefore(tag, head.firstChild));
  };

  useEffect(() => {
    reorderHeadElements();
  }, []);

  const getDefaultDescription = () => {
    // Check if article_body is defined and is an array before mapping
    if (override?.article_body && Array.isArray(override.article_body)) {
      const concatenatedDescriptions = override.article_body
        .map(
          (article) =>
            article?.rte?.rich_text_editor || override?.entry_summary || '',
        )
        .join(' ');
      return striptags(concatenatedDescriptions).trim().slice(0, 160);
    }
    // Provide a default description if article_body is not an array
    return override?.entry_summary
      ? striptags(override.entry_summary).trim().slice(0, 160)
      : '';
  };
  return (
    <Helmet>
      <title>{`${data?.meta_page_title || override?.title}  | IAPP`}</title>
      <meta
        name="description"
        content={`${data?.meta_description}` || getDefaultDescription()}
        data-rh="true"
      />
      <link
        rel="canonical"
        href={`${data?.canonical_url}` || `https://iapp.org${override?.url}`}
      />
      <meta
        property="og:title"
        // original code
        // content={`${data?.meta_page_title}` || `${override?.title}`}
        content={
          `${data?.meta_page_title}` || `${override?.article_details?.headline}`
        }
      />
      <meta
        property="og:description"
        // original code
        // content={`${data?.meta_description}` || `${override?.news_blurb}`}
        content={`${data?.meta_description}` || getDefaultDescription()}
      />
      <meta
        property="og:image"
        // original code: content={data?.meta_image?.url || override?.main_image?.url}
        content={
          data?.meta_image?.url ||
          override?.main_image?.url ||
          override?.headline_banner?.image?.url
        }
      />
      <meta property="og:type" content={`article`} />
      <meta
        property="og:url"
        content={`${data?.canonical_url}` || `https://iapp.org${override?.url}`}
      />
      <meta
        property="og:locale"
        content={l && Languages.includes(l) ? `${l}` : 'en_US'}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@IAPP" />
      <meta
        name="twitter:title"
        content={`${data?.meta_page_title}` || `${override?.title}`}
      />
      <meta
        name="twitter:description"
        content={`${data?.meta_description}` || `${override?.news_blurb}`}
      />
      <meta
        name="twitter:image"
        content={data?.meta_image?.url || override?.main_image?.url}
      />
      <meta />
      {reorderHeadElements()}
    </Helmet>
  );
}
