import { Box } from '@chakra-ui/react';
import React from 'react';

export default function RichTextEditorMB(props) {
  const {
    data,
    style,
    color,
    px,
    fontSize,
    fontColor,
    fontWeight,
    lineHeight,
    letterSpacing,
    mx,
    textOverflow,
    maxW,
    rtecontainerpx,
    wordWrap,
    ...rest
  } = props;

  return (
    <Box
      className={`RTE ${
        style && style.length > 0 ? style.join(' ').replaceAll(',', ' ') : ''
      }`}
      dangerouslySetInnerHTML={{ __html: data }}
      maxW={
        maxW ||
        style?.includes('Bordered') ||
        style?.includes('Green Background')
          ? '1010px'
          : '1200px'
      }
      mx={mx || 'auto'}
      px={px || rtecontainerpx || { base: '16px', md: '24px', lg: '32px' }}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      color={color}
      textOverflow={textOverflow}
      fontcolor={fontColor}
      wordwrap={wordWrap}
      {...rest}
      // border={'solid hotpink 2px'}
    ></Box>
  );
}
