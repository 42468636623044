import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Link,
} from '@chakra-ui/react';
import RichTextEditorMB from '../../rte';
// import ChevronDownIcon from '../../../icons/chevronDown';
// import ChevronRightIcon from '../../../icons/chevronRight';
import RightArrow from '../../../icons/rightArrow';

export default function InnerAccordion(props) {
  const { data } = props;
  return (
    <Flex direction={'column'}>
      {' '}
      <Accordion allowToggle>
        {data?.entry?.map((entry, i) => {
          return (
            <AccordionItem key={i} border={'none'}>
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionButton
                      _expanded={{ color: 'DarkGreen' }}
                      gap={'2rem'}
                      minH={'5.125rem'}
                      fontWeight={'600'}
                      lineHeight={'1.875rem'}
                      fontSize={'1.25rem'}
                      borderRadius={'20px'}
                    >
                      <AccordionIcon />
                      {/* <ChevronDownIcon color={'DarkGreen'} /> */}
                      {/* {isExpanded ? (
                        <ChevronDownIcon color={'DarkGreen'} />
                      ) : (
                        <ChevronRightIcon />
                      )} */}
                      {entry.title}
                    </AccordionButton>
                    <AccordionPanel pl={'4.25rem'} py={'0'}>
                      <RichTextEditorMB
                        data={entry.text}
                        px="initial"
                        lineHeight={'2.125rem'}
                        fontSize={'1.25rem'}
                        // style={['card']}
                      />
                      {entry.cta_link?.title && (
                        <Flex gap={'1rem'} align="center" mt={'16px'}>
                          <Link
                            href={entry.cta_link?.href}
                            fontSize={{ base: '1rem' }}
                            fontWeight={{ base: '600' }}
                            lineHeight={'1.25rem'}
                            color={'DarkGreen'}
                          >
                            {entry.cta_link?.title}
                          </Link>
                          <RightArrow style={{ color: 'DarkGreen' }} />
                        </Flex>
                      )}
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
}
