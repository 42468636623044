import React, { useState, useEffect, useRef } from 'react';
import { Text, Box, Button, Heading, Divider } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
const PopOut = ({ i, j, expanded, setExpanded, member, mt }) => {
  const [dividerHeight, setDividerHeight] = useState(0);
  const dividerRef = useRef(null);

  useEffect(() => {
    const height = dividerRef.current?.parentNode?.clientHeight;
    setDividerHeight(height * 0.8);
  }, [expanded, dividerRef]);
  return (
    <>
      {member?.headshot[0].staff_bio ? (
        <Box
          // key={j + 'pop'}
          display={expanded[i] === j ? 'block' : 'none'}
          gridColumn={'1 / -1'}
          w={{ md: '100%', base: '220px' }}
          mt={mt}
        >
          <Box
            display="flex"
            flexDir="column"
            backgroundColor="#f4f4f4"
            borderRadius="27px"
            paddingX="30px"
            paddingTop="10px"
            paddingBottom="30px"
          >
            <Button
              alignSelf="end"
              variant="unstyled"
              onClick={() => {
                const newExpanded = [...expanded];
                newExpanded[i] = null;
                setExpanded(newExpanded);
              }}
            >
              <FontAwesomeIcon fontSize="2vh" icon={solid('xmark')} />
            </Button>

            <Box
              display="flex"
              flexDirection={{ lg: 'row', base: 'column' }}
              alignItems="center"
              gap={{ lg: '50px', base: '40px' }}
              ref={dividerRef}
            >
              <Box
                backgroundColor="#f4f4f4"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                textAlign={{ md: 'start', base: 'start' }}
                alignSelf={{ lg: 'center', md: 'start', base: 'center' }}
                gap="10px"
                minW={{ lg: '20%', md: '30%', base: '100%' }}
                maxW={{ lg: '20%', md: '30%', base: '100%' }}
              >
                <Heading
                  fontWeight="800"
                  fontSize="20px"
                  marginBottom="4px"
                  letterSpacing="10%"
                >
                  {member?.headshot[0]?.title?.toUpperCase()}
                </Heading>
                <Text
                  fontSize="15px"
                  fontWeight="400"
                  fontStyle="italic"
                  letterSpacing="10%"
                >
                  {member?.headshot[0]?.staff_title}
                </Text>
                <Text fontSize="12px" fontWeight="400" letterSpacing="10%">
                  {member?.headshot[0]?.staff_info}
                </Text>
              </Box>
              <Divider
                orientation={{ lg: 'vertical', base: 'horizontal' }}
                borderColor={'grey'}
                backgroundColor="grey"
                border={{ lg: 'none', base: 'none' }}
                variant="solid"
                alignSelf={{ lg: 'center', md: 'start', base: 'center' }}
                width={{ lg: '2px', md: '33%', base: '100%' }}
                height={{ lg: `${dividerHeight}px`, base: '1px' }}
              />
              <Box
                textAlign="start"
                paddingRight={{ lg: '30px', base: 'none' }}
              >
                <Text
                  fontSize={{ md: '14px', base: '12px' }}
                  fontWeight="400"
                  letterSpacing="10%"
                >
                  {member?.headshot[0].staff_bio}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          // key={j + 'pop'}
          display={expanded[i] === j ? 'block' : 'none'}
          gridColumn={'1 / -1'}
          mt={mt}
        >
          <Box
            backgroundColor="#f4f4f4"
            borderRadius="27px"
            padding="16px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            textAlign="center"
            gap="10px"
          >
            <Button
              alignSelf="end"
              variant="unstyled"
              onClick={() => {
                const newExpanded = [...expanded];
                newExpanded[i] = null;
                setExpanded(newExpanded);
              }}
            >
              <FontAwesomeIcon fontSize="2vh" icon={solid('xmark')} />
            </Button>
            <Heading
              fontWeight="800"
              fontSize="20px"
              marginBottom="4px"
              letterSpacing="10%"
            >
              {member?.headshot[0]?.title?.toUpperCase()}
            </Heading>
            <Text
              fontSize="15px"
              fontWeight="400"
              fontStyle="italic"
              letterSpacing="10%"
            >
              {member?.headshot[0]?.staff_title}
            </Text>
            <Text fontSize="12px" fontWeight="400" letterSpacing="10%">
              {member?.headshot[0]?.staff_info}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PopOut;
