import React from 'react';
import { Box, Image, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const MainCard = ({ i, j, expanded, setExpanded, member, group }) => {
  return (
    <Box>
      <Box
        alignSelf="center"
        display="flex"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        justifyContent="space-between"
        border="1px solid #CCCCCC"
        boxShadow="1px 1px 6px 0px rgba(0,0,0,0.5)"
        borderTopWidth="8px"
        borderTopColor={group.color?.replace(' ', '')}
        borderRadius="0px 0px 20px 20px"
        paddingY="8px"
        paddingX="16px"
        minH="300px"
        w="215px"
      >
        <Image
          maxH="125px"
          minH="125px"
          maxW="115px"
          minW="115px"
          borderRadius="30px"
          objectFit="cover"
          src={member?.headshot[0]?.staff_image?.url}
          alt={member?.headshot[0]?.staff_image_alt_text}
        />
        <Box
          width="109px"
          marginY="10px"
          borderBottom="1px solid #DBDBDB"
        ></Box>
        <Text fontWeight="800" fontSize="14px" marginBottom="4px">
          {member?.headshot[0]?.title?.toUpperCase()}
        </Text>
        <Text fontSize="10px" noOfLines={3} fontStyle="italic">
          {member?.headshot[0]?.staff_title}
        </Text>
        <Text fontSize="10px" noOfLines={3}>
          {member?.headshot[0]?.staff_info}
        </Text>
        <Box display="flex" gap="5px">
          <FontAwesomeIcon
            fontSize="1.5vh"
            icon={
              expanded[i] === j ? solid('minus-circle') : solid('plus-circle')
            }
          />
          <Link
            fontFamily="open sans"
            fontWeight="700"
            fontSize="10px"
            letterSpacing="10%"
            onClick={() => {
              const newExpanded = [...expanded];
              newExpanded[i] = newExpanded[i] === j ? null : j;
              setExpanded(newExpanded);
            }}
          >
            {expanded[i] === j ? 'See Less' : 'See More'}
          </Link>
        </Box>
      </Box>

      {expanded[i] === j && (
        <Box
          marginLeft="70px"
          width="30%"
          height="44px"
          backgroundColor="#f4f4f4"
          clipPath="polygon(50% 10%, 10% 100%, 90% 100%)"
        ></Box>
      )}
    </Box>
  );
};

export default MainCard;
