import React from 'react';
import {
  Text,
  Flex,
  Button,
  Box,
  Link,
  Img,
  Divider,
  Heading,
  Hide,
  Show,
} from '@chakra-ui/react';

export default function SingleBanner(props) {
  const { data, newTab } = props;
  return (
    <Box
      bgColor={data?.theme[0]?.background_color?.replace(' ', '') || 'White'}
      //  border={'red solid 1px'}
      // minH={'597px'}
    >
      <Flex
        direction={
          data?.Img_align_left
            ? { base: 'column', md: 'row' }
            : { base: 'column-reverse', md: 'row' }
        }
        // border={'plum solid 2px'}
        // justify={'space-evenly'}
        maxW={'1440px'}
        mx={'auto'}
        h={'100%'}
        // minH={'inherit'}
      >
        {data?.Img_align_left && <ImgBlock data={data} />}
        <Flex
          // border={'orange solid 2px'}
          direction={'column'}
          justify={'center'}
          align={'start'}
          color={data?.theme[0]?.text_color || 'Black'}
          // w={{ base: '100%', md: '50%', lg: '45%' }}
          flex={1}
          pl={{ base: '45px', md: '40px', lg: '70px' }}
          pr={{ base: '45px', md: 'initial' }}
          gap={'24px'}
          my={'40px'}
        >
          <Heading
            as="h1"
            fontWeight={'800'}
            fontSize={{ base: '1.5rem', md: '2rem', lg: '2.5rem' }}
            lineHeight={{ base: '1.8rem', md: '2.813rem' }}
            // letterSpacing={'-.05rem'}
            // letterSpacing={'0px'}
          >
            {data?.headline}
          </Heading>
          <Text
            fontWeight={'500'}
            fontSize={{ base: '1rem', lg: '1.25rem' }}
            lineHeight={{ base: '1.362rem', lg: '1.5rem' }}
          >
            {data?.text}
          </Text>
          {/* {data?.button && (
            <Box>
              {data?.theme[0]?.background_color === 'Primary Green' ? (
                <ButtonMB buttonObj={data?.button} bgColor={'white'} />
              ) : (
                <ButtonMB buttonObj={data?.button} />
              )}
            </Box>
          )} */}
          {data?.cta_button?.button_link?.title && (
            <Button
              size={data?.cta_button?.button_size?.toLowerCase()}
              variant={data?.cta_button?.button_type?.toLowerCase()}
              as={Link}
              href={data?.cta_button?.button_link?.href}
              target={
                data?.cta_button?.new_window
                  ? '_blank'
                  : newTab
                  ? newTab
                  : '_self'
              }
            >
              {data?.cta_button?.button_link?.title}
            </Button>
          )}
        </Flex>
        {!data?.Img_align_left && <ImgBlock data={data} />}
      </Flex>
      <Divider />
    </Box>
  );
}

function ImgBlock(props) {
  const { data } = props;
  return (
    <Flex
      flex={{ base: '2', md: '1', lg: '2' }}
      // w={{ base: '100%', md: '50%', lg: '55%' }}
      // border={'red solid 2px'}
      // justify={'end'}
    >
      {/* Desktop  */}
      <Show above="lg">
        <Img
          src={data?.Img?.url}
          alt={data?.Img_alt_text}
          objectFit={'contain'}
          objectPosition={'left'}
        ></Img>
      </Show>
      {/* Tablet Img */}
      <Show above="md">
        <Hide above="lg">
          <Img
            src={data?.tablet_Img?.url || data?.Img?.url}
            alt={data?.tablet_Img_alt || data?.Img_alt_text}
            objectFit={'contain'}
            w={'100%'}
            loading="lazy"
          />
        </Hide>
      </Show>
      {/* Mobile Img */}
      <Hide above="md">
        <Img
          mx={'auto'}
          // border={'pink dashed 3px'}
          src={data?.mobile_Img?.url || data?.tablet_Img?.url || data?.Img?.url}
          alt={
            data?.mobile_Img_alt || data?.tablet_Img_alt || data?.Img_alt_text
          }
          objectFit={'contain'}
          w={'100%'}
        />
      </Hide>
    </Flex>
  );
}
