import { extendTheme } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/react';
// import Link from './componentThemes/links';

const Button = defineStyleConfig({
  // The styles all buttons have in common
  baseStyle: {
    fontSize: '20px',
  },
  // Three sizes: small, medium, and large
  sizes: {
    small: {
      fontSize: '18px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '32px',
      py: '10px',
      minW: '170px',
      borderRadius: '40px',
    },
    medium: {
      fontSize: '18px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '22px',
      py: '15px',
      minW: '210px',
      borderRadius: '40px',
    },
    large: {
      fontSize: '20px',
      fontWeight: '600',
      fontVariantLigatures: 'no-common-ligatures',
      px: '19px',
      py: '22px',
      minW: '240px',
      borderRadius: '40px',
    },
  },
  // Three variants: primary, secondary, and tertiary
  variants: {
    primary: {
      color: '#FFFFFF',
      bg: 'ContrastGreen',
      border: '2px solid ContrastGreen',
      _hover: {
        bg: 'DarkGreen',
        textDecoration: 'none',
      },
    },
    secondary: {
      color: 'DarkGreen',
      bg: '#FFFFFF',
      border: '2px solid DarkGreen',
      _hover: {
        color: '#FFFFFF',
        bg: 'DarkGreen',
        textDecoration: 'none',
      },
    },
    tertiary: {
      color: '#FFFFFF',
      bg: '#000000',
      border: '2px solid #000000',
      _hover: {
        color: 'DarkGreen',
        bg: '#FFFFFF',
        textDecoration: 'none',
      },
    },
  },
  // The default size and variant values
  // defaultProps: {
  //   size: 'large',
  //   variant: 'primary',
  // },
});

const theme = extendTheme({
  // Site Fonts format: 'Primary Font, Backup Font'
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Open Sans',  sans-serif`,
  },
  // Site Colors
  colors: {
    Black: '#000000',
    DarkBlack: '#000000',
    BlackOpaque: '#00000080',
    BlackText: '#1E1E1E',
    // Blue: '#1B5F8E',
    ContrastGreen: '#38761E',
    DarkBlue: '#165788',
    BlueGreen: '#51A1AA',
    BlueOpaque: '#1B5F8E80',
    DarkGreen: '#006955',
    DarkGreenOpaque: '#00695580',
    // LightGrey: '#F0F0F0',
    Grey1: '#626262',
    Grey2: '#8F8F8F',
    Grey3: '#BCBCBC',
    Grey4: '#E9E9E9',
    Grey5: '#F4F4F4',
    IAPPGreen: '#73BE53',
    // OutlineGreen: '#246C60',
    PinkRaspberry: '#8C0A39',
    PrimaryGreen: '#5E9C44',
    PrimaryGreenOpaque: '#5E9C4480',
    Purple: '#5C1D78',
    White: '#FFFFFF',
    WhiteOpaque: '#FFFFFF80',
    DarkGreentoPrimaryGreen: 'linear(to-br,#00695590, #6DB65090 )',
    GradientWhite:
      'linear(to-r, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0))',
    GradientGreen:
      'linear(to-r, rgba(94, 156, 68, 0), rgba(94, 156, 68, 0.33), rgba(94, 156, 68, 0))',
  },
  // Set Breakpoints for Responsiveness
  breakpoints: {
    sm: '320px',
    md: '750px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  // Setting styles for globally used Tags
  styles: {
    global: {
      // link styles for RTE
      a: {
        // color: 'DarkGreen',
        _hover: {
          textDecoration: 'underline',
        },
      },
      // styles for the `h1-h6`
      h1: {
        // color: 'red',
        fontSize: '40px',
        fontFamily: 'Lato',
        fontWeight: '800',
        lineHeight: '45px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h2: {
        // color: 'purple',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: ' 36px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h3: {
        // color: 'pink',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '32px',
        lineHeight: ' 36px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h4: {
        // color: 'teal',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: ' 26px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h5: {
        // color: 'aqua',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '22px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      h6: {
        // color: 'blue',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      blockquote: {
        display: 'block',
        fontFamily: 'Open Sans',
        fontWeight: '400',
        fontVariantLigatures: 'no-common-ligatures',
        position: 'relative',
        padding: '0.08em 0em 0.08em .8em',
        marginLeft: '1.5em',
      },
      Heading: {
        h1: {
          // color: 'red',
          fontSize: '40px',
          fontFamily: 'Lato',
          fontWeight: '800',
          lineHeight: '45px',
          fontVariantLigatures: 'no-common-ligatures',
        },
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: ' 36px',
        fontVariantLigatures: 'no-common-ligatures',
      },
      Img: {
        loading: 'lazy',
        // margin: '65px',
      },
      Image: {
        loading: 'lazy',
        // margin: '65px',
      },
      // blockquote:before {
      //   fontFamily: 'Lato',
      // },
      //   Table Styles
      th: { border: 'lightgray solid 1px', px: '.5rem' },
      td: { border: 'lightgray solid 1px', px: '.5rem' },
      // img: { margin: '65px' },
    },
  },
  components: {
    Button,
  },
});

export default theme;
